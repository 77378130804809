import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType} from '@angular/cdk/portal';
import {ComponentRef, inject, Injectable, Injector} from '@angular/core';
import {firstValueFrom, takeUntil, timer} from 'rxjs';
import {SnackbarTextComponent} from '../components/snackbar-text/snackbar-text.component';
import {SnackbarContainerComponent} from '../components/snackbar/snackbar-container.component';
import {ISnackbarConfig} from '../interfaces/snackbar-config.interface';
import {ISnackbarContainer} from '../interfaces/snackbar-container.interface';
import {ISnackbarDataText} from '../interfaces/snackbar-data-text.interface';
import {ISnackbarService} from '../interfaces/snackbar-service.interface';
import {SnackbarRef} from '../models/snackbar-ref';
import {PUI_SNACKBAR_CONFIG} from '../tokens/snackbar-data.token';

@Injectable()
export class SnackbarService implements ISnackbarService {
  private readonly overlay = inject(Overlay);
  private readonly injector = inject(Injector);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private snackbarRef?: SnackbarRef<any, any>;

  private DEFAULT_CONFIG: Partial<ISnackbarConfig> & {
    hasBackdrop: boolean;
    backdropClass: string;
    duration: number;
    verticalPosition: 'top' | 'bottom';
  } = {
    hasBackdrop: false,
    backdropClass: '',
    duration: 3000,
    verticalPosition: 'bottom',
  };

  private static attachContent<C>(
    component: ComponentType<C>,
    containerRef: ComponentRef<ISnackbarContainer<C>>
  ): void {
    const contentPortal = new ComponentPortal(component);
    containerRef.instance.attachPortal(contentPortal);
    containerRef.changeDetectorRef.detectChanges();
  }

  private createInjector<D, R, C>(config: ISnackbarConfig<D>, dialogRef: SnackbarRef<C, R>): Injector {
    return Injector.create(
      [
        {provide: SnackbarRef, useValue: dialogRef},
        {provide: PUI_SNACKBAR_CONFIG, useValue: config},
      ],
      this.injector
    );
  }

  private attachContainer<C, D, R>(
    snackbar: ComponentType<C>,
    config: ISnackbarConfig<D>,
    snackbarRef: SnackbarRef<C, R>
  ): ISnackbarContainer<C> | null {
    const injector = this.createInjector(config, snackbarRef);
    const containerPortal: ComponentPortal<ISnackbarContainer<C>> = new ComponentPortal(
      SnackbarContainerComponent,
      null,
      injector
    );
    const containerRef = snackbarRef.overlayRef?.attach(containerPortal);
    snackbarRef.overlayRef?.updatePosition();

    if (snackbarRef.overlayRef && containerRef) {
      snackbarRef.containerRef = containerRef;
      SnackbarService.attachContent(snackbar, containerRef);
    }

    return containerRef?.instance ?? null;
  }

  private getOverlayConfig<D>(config: ISnackbarConfig<D>): OverlayConfig {
    const globalPositionStrategy = this.overlay.position().global();
    const margin = '24px';

    const positionStrategy =
      config.verticalPosition === 'bottom' ? globalPositionStrategy.bottom(margin) : globalPositionStrategy.top(margin);

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      maxWidth: config.maxWidth,
      positionStrategy: positionStrategy.centerHorizontally(),
    });
  }

  private createOverlay<D>(config: ISnackbarConfig<D>): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);

    return this.overlay.create(overlayConfig);
  }

  open<C, D, R>(component: ComponentType<C>, config?: ISnackbarConfig<D>): SnackbarRef<C, R> {
    const ref = this.snackbarRef;
    ref?.close();

    const dialogConfig = {...this.DEFAULT_CONFIG, ...config};
    const snackbarRef = new SnackbarRef<C, R>();
    this.snackbarRef = snackbarRef;

    (ref?.afterClosedInternal$ ? firstValueFrom(ref.afterClosedInternal$) : Promise.resolve()).then(() => {
      snackbarRef.overlayRef = this.createOverlay(dialogConfig);
      if (snackbarRef.overlayRef.hostElement.parentElement) {
        snackbarRef.overlayRef.hostElement.parentElement.style.zIndex = '10000000000';
      }

      this.attachContainer(component, dialogConfig, snackbarRef);

      if (!config?.persistent) {
        timer(config?.duration ?? this.DEFAULT_CONFIG.duration)
          .pipe(takeUntil(snackbarRef.afterClosedInternal$))
          .subscribe(() => {
            snackbarRef.close();
          });
      }
    });

    return snackbarRef;
  }

  text<D extends ISnackbarDataText>(
    text: string,
    config?: ISnackbarConfig<D>
  ): SnackbarRef<SnackbarTextComponent, void> {
    return this.open<SnackbarTextComponent, {text: string}, void>(SnackbarTextComponent, {
      ...config,
      data: {...(config?.data ?? {}), text},
    });
  }
}
