import {Component, inject, ViewEncapsulation} from '@angular/core';
import {PUI_SNACKBAR_CONFIG} from '@pui/components/snackbar';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'pss-snackbar',
  standalone: true,
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarComponent {
  readonly data: string = inject(PUI_SNACKBAR_CONFIG).data;
}
