import {ImagesUrls, User} from '../../models/user.model';
import {MinScreenIntermidiateSize} from '../../shared/enums/min-screen-intermidiate-size';
import {IntermediateImageName} from '../../shared/enums/intermediate-image-names';
import {ThumbnailImageSize} from '../../shared/enums/thumbnail-image-size';
import {PSSPlatformEnvironment} from '@px/pss/platform-env';
import {getDevicePixelRatio} from '../../shared/functions/device-pixel-ratio.functions';

export function countUrls(user: User | null): ImagesUrls | null {
  if (!user) return null;

  const platform = PSSPlatformEnvironment.getSelf();
  const dpr = getDevicePixelRatio();
  let intermediateImageName = IntermediateImageName.FHD;

  if (screen.width * dpr > MinScreenIntermidiateSize.WIDTH || screen.height * dpr > MinScreenIntermidiateSize.HEIGHT) {
    intermediateImageName = IntermediateImageName.UHD;
  }

  const baseImagesUrl = `${platform.IMAGES_PATH}${platform.SCOPE}/STORE/`;

  // Since user has access to someone else's slideshow (sample slideshow), you should replace USER_ID with the owner's id
  const urls: ImagesUrls = {
    imageUrl: `${baseImagesUrl}${intermediateImageName}/${dpr}x/uUSER_ID/`,
    thumbnailUrl: `${baseImagesUrl}t/${dpr}x/uUSER_ID/`,
    editorThumbnailUrl: `${baseImagesUrl}${ThumbnailImageSize.EDITOR_PAGE}/${dpr}x/uUSER_ID/`,
    managerThumbnailUrl: `${baseImagesUrl}${ThumbnailImageSize.MANAGER_PAGE}/${dpr}x/uUSER_ID/`,
    imageUrlHParam: user.user_type_hash ? `uh=${user.user_type_hash}` : 'uh',
  };

  if (user.is_sharpen) {
    urls.imageUrlHParam += dpr === 1 ? '&sharp_radius=1&sharp_amount=45' : '&sharp_radius=1&sharp_amount=50';
  }
  return urls;
}
