import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {CdkPortalOutlet, ComponentPortal, PortalModule} from '@angular/cdk/portal';
import {animate, AnimationEvent, state, style, transition, trigger} from '@angular/animations';
import {ISnackbarContainer} from '../../interfaces/snackbar-container.interface';
import {filter, map} from 'rxjs';

@Component({
  selector: 'mds-snackbar',
  templateUrl: './snackbar-container.component.html',
  styleUrls: ['./snackbar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PortalModule],
  animations: [
    trigger('dialogContainer', [
      state('void, exit', style({opacity: 0, transform: 'scale(0.95)'})),
      state('enter', style({opacity: 1, transform: 'scale(1)'})),
      transition('* => *', animate('150ms')),
    ]),
  ],
})
export class SnackbarContainerComponent<C> implements ISnackbarContainer<C>, OnDestroy {
  private animationStateChanged$ = new EventEmitter<AnimationEvent>();
  private portalComponentRef: ComponentRef<C> | undefined;

  @ViewChild(CdkPortalOutlet, {static: true}) cdkPortalOutlet?: CdkPortalOutlet;
  transformOrigin = '';
  animationState: 'void' | 'enter' | 'exit' = 'enter';

  @Output()
  open$ = this.animationStateChanged$.pipe(
    filter(event => event.phaseName === 'done' && event.toState === 'enter'),
    map(() => undefined)
  );

  @Output()
  closeStart$ = this.animationStateChanged$.pipe(
    filter(event => event.phaseName === 'start' && event.toState === 'exit'),
    map(() => undefined)
  );

  @Output()
  closeEnd$ = this.animationStateChanged$.pipe(
    filter(event => event.phaseName === 'done' && event.toState === 'exit'),
    map(() => undefined)
  );

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onAnimationStart(event: AnimationEvent): void {
    this.animationStateChanged$.emit(event);
  }

  onAnimationDone(event: AnimationEvent): void {
    this.animationStateChanged$.emit(event);
  }

  startExit(): void {
    this.animationState = 'exit';
    this.cdr.detectChanges();
  }

  attachPortal(portal: ComponentPortal<C>): void {
    if (this.cdkPortalOutlet?.hasAttached()) {
      return;
    }

    this.portalComponentRef = this.cdkPortalOutlet?.attachComponentPortal(portal);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.animationStateChanged$.complete();
  }
}
