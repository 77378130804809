import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {PricingDomainModule} from '@ps/pricing-domain';
import {MdsDropdownModule} from '@pui/components/dropdown';
import {ConfirmationComponent} from '@pui/components/icon';
import {caPixelluLogo, MdsIconComponent, mdsProvideIcons, pxClose} from '@pui/components/icon-core';
import {MdsSpinnerComponent} from '@pui/components/loading-indicators';
import {PricingFeaturePricingCardsModule} from '@px/pricing/feature-pricing-cards';
import {SubscriptionManagerDomainModule} from '@px/subscription-manager/domain';
import {UiButtonModule} from '@px/ui-components-px-button';
import {PricingDialogComponent} from './components/pricing-dialog.component';
import {SubscriptionActivationWaitingDialogComponent} from './components/subscription-activation-waiting-dialog.component';
import {SubscriptionManagerDialogComponent} from './components/subscription-manager-dialog.component';
import {SubscriptionManagerDialogService} from './services/subscription-manager-dialog.service';
import {MDS_DIALOG_PROVIDER} from '@pui/components/dialog';

@NgModule({
  imports: [
    CommonModule,
    PricingFeaturePricingCardsModule,
    MdsDropdownModule,
    PricingDomainModule,
    TranslateModule,
    UiButtonModule,
    SubscriptionManagerDomainModule,
    MdsIconComponent,
    ConfirmationComponent,
    MdsSpinnerComponent,
  ],
  declarations: [
    SubscriptionActivationWaitingDialogComponent,
    SubscriptionManagerDialogComponent,
    PricingDialogComponent,
  ],
  providers: [MDS_DIALOG_PROVIDER, SubscriptionManagerDialogService, mdsProvideIcons({pxClose, caPixelluLogo})],
})
export class SubscriptionManagerFeatureModule {}
