import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, Input, Optional, TemplateRef} from '@angular/core';
import {MdsButtonComponent} from '@pui/components/button';
import {aaClose, MdsIcon, MdsIconComponent, mdsProvideIcons} from '@pui/components/icon-core';
import {ISnackbarConfig} from '../../interfaces/snackbar-config.interface';
import {ISnackbarTextButtonRefContext} from '../../interfaces/snackbar-text-button-ref.interface';
import {SnackbarRef} from '../../models/snackbar-ref';
import {PUI_SNACKBAR_CONFIG} from '../../tokens/snackbar-data.token';

@Component({
  selector: 'mds-snackbar-text',
  templateUrl: './snackbar-text.component.html',
  styleUrls: ['./snackbar-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MdsIconComponent, MdsButtonComponent, NgIf],
  providers: [mdsProvideIcons({aaClose})],
})
export class SnackbarTextComponent {
  private textInstance: string | null = null;
  private actionInstance: string | null = null;
  private iconInstance: MdsIcon | null = null;
  private hideCloseButtonInstance = false;

  @Input() actionsTemplateRef: TemplateRef<ISnackbarTextButtonRefContext> | null = null;

  get text(): string {
    return this.textInstance ?? this.config?.data?.text ?? '';
  }

  @Input()
  set text(value: string) {
    this.textInstance = value;
  }

  get action(): string | null {
    return this.actionInstance ?? this.config?.action?.label ?? null;
  }

  @Input()
  set action(value: string | null) {
    this.actionInstance = value;
  }

  get icon(): MdsIcon | null {
    return this.iconInstance ?? this.config?.action?.icon ?? null;
  }

  @Input()
  set icon(value: MdsIcon | null) {
    this.iconInstance = value;
  }

  get hideCloseButton(): boolean {
    return this.hideCloseButtonInstance ?? false;
  }

  constructor(
    @Optional() @Inject(PUI_SNACKBAR_CONFIG) private readonly config: ISnackbarConfig<{text: string}>,
    @Optional() private readonly snackbarRef: SnackbarRef<SnackbarTextComponent, void>
  ) {}

  close = (): void => {
    this.snackbarRef?.close();
  };

  call = (): void => {
    this.close();
    this.config.action?.callback?.();
  };
}
