<div class="c-snackbar__inner">
  <mds-icon *ngIf="icon" class="c-snackbar__icon" [icon]="icon" />
  <div class="c-snackbar__body">
    {{ text }}
  </div>
  <div class="c-snackbar__actions c-snackbar__actions--buttons">
    <ng-container *ngIf="action">
      <mds-button (click$)="call()">
        {{ action }}
        <ng-container *ngIf="icon">
          <mds-icon [icon]="icon" />
        </ng-container>
      </mds-button>
    </ng-container>
    <ng-container *ngIf="!hideCloseButton">
      <mds-button class="c-snackbar__close-btn" variant="fab" (click$)="close()">
        <mds-icon icon="aaClose" />
      </mds-button>
    </ng-container>
  </div>
</div>
