import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiSnackbarContainerComponent} from './ui-snackbar-container/ui-snackbar-container.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {UiButtonModule} from '@px/ui-components-px-button';

@NgModule({
  imports: [CommonModule, OverlayModule, PortalModule, UiButtonModule],
  declarations: [UiSnackbarContainerComponent],
  exports: [UiSnackbarContainerComponent],
})
export class UiSnackbarContainerModule {}
