import {Pipe, PipeTransform} from '@angular/core';
import {PlatformEnvironment} from '@px/shared/env';

@Pipe({
  name: 'framesToMs',
  standalone: true,
})
export class FramesToMsPipe implements PipeTransform {
  constructor(private readonly platform: PlatformEnvironment) {}

  transform(value: string | number): number {
    return typeof value === 'number' ? (value * 1000) / this.platform.COMMON_SETTINGS.FRAMES_PER_SEC : Number(value);
  }
}
