import {DEFAULT_THUMBNAIL_SIZE_ID, THUMBNAIL_SIZES} from '../../editor/components/image-area/thubnail-sizes';

export function getSelectedThumbnailSizeId(editorThumbnailSize: string): number {
  let thumbnailSizeId = DEFAULT_THUMBNAIL_SIZE_ID;
  for (const [id, size] of THUMBNAIL_SIZES) {
    if (size.value === editorThumbnailSize) {
      thumbnailSizeId = id;
      break;
    }
  }
  return thumbnailSizeId;
}
