import {Action} from '@ngrx/store';
import {Slideshow} from '../../../../../models/slideshow.model';
import {SlideshowChangesApi} from '../../../../../core/interfaces/slideshow-changes-api';

export enum ShareDialogActionTypes {
  SAVE = '[Share Dialog] Save',
  SAVE_SUCCESS = '[Share Dialog] Save Success',
  SAVE_FAILURE = '[Share Dialog] Save Failure',
  SHARE_DIALOG_OPEN = '[Slideshows] Share dialog open',
  SHARE_DIALOG_CLOSE = '[Slideshows] Share dialog close',
}

export class Save implements Action {
  readonly type = ShareDialogActionTypes.SAVE;

  constructor(
    public slideshow: Slideshow,
    public data: SlideshowChangesApi
  ) {}
}

export class SaveSuccess implements Action {
  readonly type = ShareDialogActionTypes.SAVE_SUCCESS;

  constructor(
    readonly uniqueIdentifier: string,
    readonly data: SlideshowChangesApi
  ) {}
}

export class SaveFailure implements Action {
  readonly type = ShareDialogActionTypes.SAVE_FAILURE;
}

export class ShareDialogOpen implements Action {
  readonly type = ShareDialogActionTypes.SHARE_DIALOG_OPEN;

  constructor(
    public slideshow: Slideshow,
    public isAfterPublished?: boolean,
    public currentTabIndex?: number,
    public isFirstPublishedSlideshow = false
  ) {}
}

export class ShareDialogClose implements Action {
  readonly type = ShareDialogActionTypes.SHARE_DIALOG_CLOSE;
}

export type ShareDialogActionsUnion = Save | SaveSuccess | SaveFailure | ShareDialogOpen | ShareDialogClose;
