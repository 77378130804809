import {Pipe, PipeTransform} from '@angular/core';
import {PlatformEnvironment} from '@px/shared/env';

@Pipe({
  name: 'framesToS',
  standalone: true,
})
export class FramesToSPipe implements PipeTransform {
  constructor(private readonly platform: PlatformEnvironment) {}

  transform(value: number | string): number {
    return typeof value === 'number' ? value / this.platform.COMMON_SETTINGS.FRAMES_PER_SEC : Number(value);
  }
}
