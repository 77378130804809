import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import {PSSPlatformEnvironment} from '@px/pss/platform-env';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app/app.state';
import {take} from 'rxjs/operators';
import {SlideshowSelectors} from '../main/store/slideshow/slideshow.selectors';
import {Router} from '@angular/router';
import {AppRoutes} from '../models/routes.enum';
import {ISentrySlideshowContext} from '../core/interfaces/sentry-slideshow-context';
import {Contexts} from '@sentry/types';
import {SentryErrorHandlerService} from '@px/shared/data-access/sentry';
import getActualSlideshowState = SlideshowSelectors.getActualSlideshowState;

@Injectable()
export class SentryPssErrorHandlerService extends SentryErrorHandlerService {
  constructor(
    protected readonly platform: PSSPlatformEnvironment,
    private readonly store$: Store<AppState>,
    private readonly router: Router
  ) {
    super(platform);
  }

  private async getSlideshowContext(): Promise<ISentrySlideshowContext> {
    const state = await this.store$.select(getActualSlideshowState).pipe(take(1)).toPromise();

    const segments = Object.entries(state.segmentList.entities ?? {})?.map(([, segment]) => ({
      photos: segment.photosIds?.length,
      audio: segment.audio?.id,
      bm: segment.beat_matching_template?.id,
    }));

    return {
      segments: segments.length,
      photos: segments.map(({photos}) => photos).join(', '),
      audio: segments.map(({audio}) => audio ?? 'EMPTY').join(', '),
      bm: segments.map(({bm}) => bm ?? 'EMPTY').join(', '),
    };
  }

  override async handleError(error: Error & {originalError: Error}): Promise<void> {
    if (this.platform.SENTRY_ENABLED) {
      let contexts: Contexts = {};

      if (this.router.url?.includes(AppRoutes.EDITOR)) {
        contexts = {
          slideshow: await this.getSlideshowContext(),
        };
      }

      const id = Sentry.captureException(error.originalError || error, {contexts});
      console.error('Sentry event id', id);
    }

    console.error(error.originalError || error);
  }
}
