import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'px-search-slideshow',
  standalone: false,
  templateUrl: './search-slideshow.component.html',
  styleUrls: ['./search-slideshow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSlideshowComponent implements OnInit, OnDestroy {
  private readonly DEBOUNCE_TIME_MS = 500;

  isSearchOpened = false;
  searchField = new UntypedFormControl('');

  @Output() search$ = new EventEmitter<{name: string}>();

  ngOnInit(): void {
    this.searchField.valueChanges
      .pipe(debounceTime(this.DEBOUNCE_TIME_MS), distinctUntilChanged())
      .subscribe((name: string) => {
        this.search$.emit({name});
      });
  }

  ngOnDestroy(): void {
    if (this.searchField.value) {
      this.search$.emit({name: ''});
    }
  }
}
