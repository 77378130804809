import {PSSPlatformEnvironment} from '@px/pss/platform-env';

export function getDevicePixelRatio(): number {
  let ratio = 1;

  const screenObj: Screen = window.screen;
  if ('systemXDPI' in screenObj && 'logicalXDPI' in screenObj && screenObj['systemXDPI'] > screenObj['logicalXDPI']) {
    ratio = (screenObj['systemXDPI'] as number) / (screenObj['logicalXDPI'] as number);
  } else if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  } else {
    ratio = 1;
  }

  if (ratio > 1) {
    const zoom = Math.round((window.outerWidth / window.innerWidth) * 100) / 100;
    if (zoom !== 1) ratio = ratio / zoom;
  }

  if (ratio < 1) ratio = 1;
  ratio = Math.round(ratio * 100) / 100;
  if (ratio > PSSPlatformEnvironment.getSelf().COMMON_SETTINGS.MAX_DPR) {
    ratio = PSSPlatformEnvironment.getSelf().COMMON_SETTINGS.MAX_DPR;
  }

  return isNaN(ratio) ? 1 : ratio;
}
