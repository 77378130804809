import {Inject, Injectable} from '@angular/core';
import {AbTestOptions} from './module';
import {AbTestForRealUser, RandomExtractor} from './classes';
import {AB_TESTS_RANDOM_EXTRACTOR_TOKEN, CONFIG} from './injection-tokens';
import {error} from './error';

@Injectable()
export class AbTestsService {
  private _tests: {[x: string]: AbTestForRealUser} = {};
  private _randomExtractor: RandomExtractor;
  private _defaultScope = 'default';

  constructor(
    @Inject(CONFIG) configs: AbTestOptions[],
    @Inject(AB_TESTS_RANDOM_EXTRACTOR_TOKEN) randomExtractor: RandomExtractor
  ) {
    this._randomExtractor = randomExtractor;
    for (const config of configs) {
      let scope: string = this._defaultScope;
      if (config.scope) {
        scope = config.scope;
      }
      if (this._tests[scope]) {
        error('Test with scope <' + scope + '> cannot be initialized twice');
      }
      this.setupTestForRealUser(scope, this.filterVersions(config.versions), config);
    }
  }

  getVersion(scope?: string): string {
    return this.getTest(scope).getVersion();
  }

  setVersion(version: string, scope?: string) {
    return this.getTest(scope).setVersion(version);
  }

  shouldRender(versions: string[], scope: string): boolean {
    return this.getTest(scope).shouldRender(versions);
  }

  private getTest(scope?: string): AbTestForRealUser {
    const scopeOrDefault = scope || this._defaultScope;
    if (!this._tests[scopeOrDefault]) {
      error('Test with scope <' + scopeOrDefault + '> has not been defined');
    }
    return this._tests[scopeOrDefault];
  }

  private filterVersions(versions: string[]): string[] {
    const resp: string[] = [];
    if (versions.length < 2) {
      error('You have to provide at least two versions');
    }
    for (const version of versions) {
      if (resp.indexOf(version) !== -1) {
        error('Version <' + version + '> is repeated in the array of versions [ ' + versions.join(', ') + ' ]');
      }
      resp.push(version);
    }
    return resp;
  }

  private setupTestForRealUser(scope: string, versions: string[], config: AbTestOptions) {
    const chosenVersion: string = this.generateVersion({
      versions: versions,
      weights: config.weights,
    });
    this._tests[scope] = new AbTestForRealUser(versions, chosenVersion);
  }

  private generateVersion(config: {versions: string[]; weights?: {[x: string]: number}}): string {
    this._randomExtractor.setWeights(this.processWeights(config.weights || {}, config.versions));
    this._randomExtractor.setVersions(config.versions);
    return this._randomExtractor.run();
  }

  private processWeights(weights: {[x: string]: number}, versions: string[]): [number, string][] {
    const processedWeights: [number, string][] = [];
    let totalWeight = 0;
    const tempVersions: string[] = versions.slice(0);
    let index = -100;
    for (const key in weights) {
      index = tempVersions.indexOf(key);
      if (index === -1) {
        error('Weight associated to <' + key + '> which is not included in versions [ ' + versions.join(', ') + ' ]');
      }
      tempVersions.splice(index, 1);
      totalWeight += this.roundFloat(weights[key]);
      processedWeights.push([totalWeight, key]);
    }
    if (index === -100) {
      return [];
    }
    if (totalWeight >= 100) {
      error('Sum of weights is <' + totalWeight + '>, while it should be less than 100');
    }
    const remainingWeight: number = this.roundFloat((100 - totalWeight) / tempVersions.length);
    for (const version of tempVersions) {
      totalWeight += remainingWeight;
      processedWeights.push([totalWeight, version]);
    }
    processedWeights[processedWeights.length - 1] = [100, processedWeights[processedWeights.length - 1][1]];
    return processedWeights;
  }

  private roundFloat(x: number): number {
    return Math.round(x * 1000) / 1000;
  }
}
