import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IPSSAuthService, ISaveLastTransitionPicInput} from '../models/auth-service.interface';
import {AuthGqlFacade, IApiResponse as ApiResponse, PreferencesGqlService} from '@px/shared/api';
import {PSSPlatformEnvironment, PSSPlatformFeatures} from '@px/pss/platform-env';
import {AuthApiService} from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class PSSAuthFacade extends AuthGqlFacade implements IPSSAuthService {
  // TODO: remove picAuthService usage when PIC will be removed
  private readonly PSSPicAuthService = new AuthApiService();
  private readonly preferencesService = inject(PreferencesGqlService);
  private readonly platform = inject(PSSPlatformEnvironment);

  saveLastTransition(data: ISaveLastTransitionPicInput): Observable<ApiResponse> {
    const inputMetaData = {
      lastChosenTransitionType: data.transition_id,
      lastChosenTransitionDuration: data.transition_duration,
    };

    return this.platform.hasFeature(PSSPlatformFeatures.EDITOR_GRAPHQL_API)
      ? this.preferencesService.save({meta_data: inputMetaData})
      : this.PSSPicAuthService.saveLastTransition(data);
  }
}
