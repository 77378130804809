import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  SaveSuccess,
  ShareDialogActionTypes,
} from '../../main/shared/components/share-dialog/store/share-dialog.actions';
import {mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {addOrUpdateSlideshow} from '../../main/manager/store/manager.actions';
import {ManagerSlideshow} from '../../models/manager.model';
import {UpdateSlideshow} from '../../main/store/slideshow/slideshow.actions';
import {parseSlideshowDate} from '../../main/store/slideshow/slideshow-utils';
import {Slideshow} from '../../models/slideshow.model';

@Injectable()
export class AppEffects {
  shareDialogSaveSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(ShareDialogActionTypes.SAVE_SUCCESS),
      mergeMap(({uniqueIdentifier, data}: SaveSuccess) => {
        return of(
          addOrUpdateSlideshow(data.slide_show as unknown as ManagerSlideshow, uniqueIdentifier),
          new UpdateSlideshow(parseSlideshowDate(data.slide_show as unknown as Slideshow<number>))
        );
      })
    )
  );

  constructor(private readonly actions: Actions) {}
}
