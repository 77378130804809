import {GlobalState} from '../global/global.state';
import {GlobalActionsUnion, GlobalActionTypes} from './global.actions';

export const initialGlobalState: GlobalState = {
  isOnline: true,
  offlineSnackbarRef: null,
  isSupportedBrowser: true,
  isMobileDevice: false,
};

// Reducer
export function globalStateReducer(state: GlobalState = initialGlobalState, action: GlobalActionsUnion): GlobalState {
  switch (action.type) {
    case GlobalActionTypes.REDIRECT_MOBILE_DEVICE: {
      return {
        ...state,
        isMobileDevice: true,
      };
    }

    case GlobalActionTypes.CONNECTION_OFFLINE: {
      return {
        ...state,
        isOnline: false,
      };
    }

    case GlobalActionTypes.CONNECTION_ONLINE: {
      return {
        ...state,
        isOnline: true,
      };
    }

    case GlobalActionTypes.OFFLINE_SNACKBAR_HIDE: {
      return {
        ...state,
        offlineSnackbarRef: null,
      };
    }

    case GlobalActionTypes.OFFLINE_SNACKBAR_SHOW: {
      return {
        ...state,
        offlineSnackbarRef: action.snackBarRef,
      };
    }

    case GlobalActionTypes.REDIRECT_UNSUPPORTED_BROWSER: {
      return {
        ...state,
        isSupportedBrowser: false,
      };
    }

    default:
      return state;
  }
}
