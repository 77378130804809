import {ModuleWithProviders, NgModule} from '@angular/core';
import {AbTestsService} from './service';
import {AB_TESTS_RANDOM_EXTRACTOR_TOKEN, CONFIG} from './injection-tokens';
import {RandomExtractor} from './classes';
import {AbTestVersionDirective} from './directive';

export interface AbTestOptions {
  versions: string[];
  scope?: string;
  weights?: {
    [x: string]: number;
  };
}

@NgModule({
  imports: [AbTestVersionDirective],
  exports: [AbTestVersionDirective],
})
export class AbTestsModule {
  static forRoot(configs: AbTestOptions[]): ModuleWithProviders<AbTestsModule> {
    return {
      ngModule: AbTestsModule,
      providers: [
        AbTestsService,
        {provide: CONFIG, useValue: configs},
        {provide: AB_TESTS_RANDOM_EXTRACTOR_TOKEN, useClass: RandomExtractor},
      ],
    };
  }
}
