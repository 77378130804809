import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {UnsupportedBrowserComponent} from './shared/components/unsupported-browser/unsupported-browser.component';
import {MobileComponent} from './shared/components/mobile/mobile.component';
import {CheckAuthGuard} from './guards/check-auth.guard';
import {UnsupportedBrowserGuard} from './guards/unsupported-browser-guard.service';
import {AppRoutes} from './models/routes.enum';

const routes: Routes = [
  {
    path: AppRoutes.LOGIN,
    redirectTo: '',
  },
  {
    path: AppRoutes.UNSUPPORTED_BROWSER,
    component: UnsupportedBrowserComponent,
    canActivate: [UnsupportedBrowserGuard],
  },
  {
    path: AppRoutes.MOBILE,
    component: MobileComponent,
  },
  {
    path: AppRoutes.AUTH,
    loadChildren: () => import('@px/photographer-auth/feature-auth').then(m => m.PhotographerAuthFeatureAuthModule),
    data: {
      fallbackUrl: `/${AppRoutes.LOGIN}`,
    },
  },
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [CheckAuthGuard],
  },
  {
    path: '**',
    redirectTo: `/${AppRoutes.PAGE_NOT_FOUND}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
  providers: [CheckAuthGuard, UnsupportedBrowserGuard],
})
export class AppRoutingModule {}
